<script >
import SideBar from "@/components/SideBar.vue";
import FooterSection from "@/components/Footer.vue";
import NavbarSection from "@/components/NavBar.vue";

export default {
  name:"HomePage",
  components: {NavbarSection, FooterSection, SideBar}
}
</script>

<template>
  <div id="wrapper">
    <SideBar />
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <NavbarSection />
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </div>
      <FooterSection />
    </div>
  </div>
</template>

<style>

</style>