import {createRouter, createWebHistory} from "vue-router";
import CreateUser from "@/components/CreateUser.vue";
import UserTask from "@/components/UserTask.vue";
import CreateTask from "@/components/CreateTask.vue";
import TaskDetails from "@/components/TaskDetails.vue";
import Login from "@/components/Login.vue";
import HomePage from "@/components/HomePage.vue";
import Users from "@/components/Users.vue";
import NotFound from "@/components/NotFound.vue";
// import Cookies from "js-cookie";
// import EncryptionUtility from "@/encrypt";

const routes = [

    {path: '/login', name: 'login', component: Login},
    {
        path: '/',
        name: 'home',
        component: HomePage,
        children: [
            {path: '', name: 'users', component: Users},
            {path: '/create-user', name: 'create', component: CreateUser},
            {path: '/user-task/:id', name: 'tasks', component: UserTask},
            {path: '/create-task', name: 'create-task', component: CreateTask},
            {path: '/task-details/:id', name: 'task-details', component: TaskDetails}
        ]
    },
    {
        path: "/:notFound",
        component: NotFound,
        name:'notfound'
    },
];
const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem("token");
    // const admin = Cookies.get("TokenData");
    // const encryptionUtility = new EncryptionUtility("AdminKey");
    // const data = encryptionUtility.decrypt(admin)
    if (loggedIn === null && to.name !== 'login') {
        next({name: 'login'});
    } else if (loggedIn !== null && to.name === 'login') {
        next({name: 'users'});
    } else {
        next();
    }
});

export default router;