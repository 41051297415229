<script >
export default {
  name:"404Not"
}
</script>

<template>
  <div class="container d-flex align-items-center justify-content-center" style="height: 100vh;">
    <h1>404 - صفحه مورد نظر یافت نشد</h1>
  </div>
</template>
<style scoped>

</style>