<script>
import {reactive, ref} from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import store from "@/store";
import {mapState} from "vuex";
import router from "@/router";
import EncryptionUtility from "@/encrypt";
export default {

  name:"LoginPage",
  computed: {
    ...mapState(["token"]),
  },
  setup(){
    const encryptionUtility = new EncryptionUtility("AdminKey");
    const loading = ref(false);
    const form = reactive({
      username: "",
      password: "",
    });
    function Login(){
      loading.value = true;
      axios.post('https://support.afrandweb.com/api/api/auth-login',{
        username:form.username,
        password:form.password,
      }).then((res)=>{
        loading.value = false;
        if(res.data['status'] === 'ok'){
          Swal.fire(
              'عملیات موفق',
              'ورود موفقیت آمیز',
              'success'
          )
          store.commit("setToken", res.data['token']);
          store.commit("setAdmin", encryptionUtility.encrypt(res.data['isAdmin'].toString()));

          router.push({name: 'users'})
        }else{
          Swal.fire(
              'توجه',
              'نام کاربری یا رمز عبور اشتباه است!',
              'error'
          )
        }
      }).catch(function (){
        loading.value = false;
        Swal.fire(
            'توجه',
            'نام کاربری یا رمز عبور اشتباه است!',
            'error'
        )
      });
    }

    return {form,Login,loading};
  }

}
</script>

<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="login-container">
          <h2 class="login-title">سیستم تسک افرند وب</h2>
          <form class="login-form" @submit.prevent="Login">
            <div class="form-group">
              <label for="username">ایمیل</label>
              <input type="email" class="form-control" v-model.trim="form.username" id="username" name="username" required>
            </div>
            <div class="form-group">
              <label for="password">رمز عبور</label>
              <input type="password" v-model.trim="form.password" class="form-control" id="password" name="password" required>
            </div>
            <button class="btn-login loading" v-if="loading">
              <span class="loading-text">لطفا صبر کنید...</span>
            </button>
            <button type="submit" v-else class="btn-login">ورود به حساب کاربری</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
body {
  background-color: #f2f2f2;
}
.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login-title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}
.login-form label {
  font-weight: bold;
}
.login-form .form-control {
  border-radius: 8px;
  margin-bottom: 15px;
}
.btn-login {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 8px;
  color: #fff;
  background-color: #007bff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-login:hover {
  background-color: #0056b3;
}

.loading {
  position: relative;
  overflow: hidden;
}

.loading-text {
  opacity: 0;
  transition: opacity 0.3s;
}

.loading:active .loading-text {
  opacity: 1;
}

.loading:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  animation: loading-anim 1s infinite;
}

@keyframes loading-anim {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
</style>
