import CryptoJS from "crypto-js";

class EncryptionUtility {
    constructor(secretKey) {
        this.secretKey = secretKey;
    }

    encrypt(data) {
        return CryptoJS.AES.encrypt(data, this.secretKey).toString();
    }

    decrypt(cipherText) {
        const bytes = CryptoJS.AES.decrypt(cipherText, this.secretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
}

export default EncryptionUtility;
