<script >
import axios from "axios";
import {useRoute} from "vue-router";
import Swal from "sweetalert2";
import {ref} from "vue";

export default {
  name : "UserTasks",
  setup(){
    const router = useRoute();
    const data = ref([]);
    const loading = ref(true);
    const loadingUpdate = ref([]);
    const loadingDelete = ref([false]);

    function getUserTask(){
      axios.get(`https://support.afrandweb.com/api/api/tasks/${router.params.id}`,{
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response) => {
        loading.value = false;

        data.value = response.data;
      }).catch(function (error){
        loading.value = false;
        Swal.fire(
            'توجه',
            'خطا',
            'error'
        )
        console.log(error);
      });
    }

    function updateStatus(status,taskId){
      axios.put(`https://support.afrandweb.com/api/api/tasks/update/${status}/${taskId}`,{},{
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then(() => {
        loadingUpdate.value[taskId] = false;
        getUserTask();

        Swal.fire(
            'عملیات موفق',
            'وضعیت تسک با موفقیت ویرایش شد',
            'success'
        )
      }).catch(function (error){
        loadingUpdate.value[taskId] = false;
        Swal.fire(
            'توجه',
            'خطا',
            'error'
        )
        console.log(error);
      });
    }

    function deleteTask(taskId,index){
      loadingDelete.value[index] = true;
      axios.delete(`https://support.afrandweb.com/api/api/tasks/delete/${taskId}`,{
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then(() => {
        loadingDelete.value[index] = false;
        getUserTask();

        Swal.fire(
            'عملیات موفق',
            'تسک با موفقیت حذف شد',
            'success'
        )
      }).catch(function (){
        loadingDelete.value[index] = false;
        Swal.fire(
            'توجه',
            'خطا',
            'error'
        )
      });
    }

    getUserTask();

    return {data,loading,router,updateStatus,deleteTask,loadingDelete}

  }
}
</script>

<template>

  <div class="mb-4">
    <h5 class="font-weight-bold">تسک های کاربر : {{ router.query.title }}</h5>
  </div>

  <hr>
  <table class="table table-bordered table-striped text-center">

    <thead>
    <tr>
      <th>#</th>
      <th>عنوان</th>
      <th>اولویت</th>
      <th>وضعیت</th>
      <th>تاریخ ایجاد</th>
      <th>تاریخ ویرایش</th>
      <th>عملیات</th>

    </tr>
    </thead>
    <tbody>
    <tr v-for="(task, index) in data" :key="task.id">
      <th>
        {{ (index + 1) }}
      </th>
      <th>
        {{task.title}}
      </th>
      <th :class="task.priority === 0 ? 'text-success' : task.priority === 1 ? 'text-warning' : 'text-danger'">
        {{task.priority === 0 ? 'پایین' : task.priority === 1 ? 'متوسط' : 'بالا'}}
      </th>
      <th>
        <div class="dropdown">
          <button v-if="task.status === 0" class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            برای انجام
          </button>
          <button v-if="task.status === 1" class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            درحال انجام
          </button>
          <button v-if="task.status === 2" class="btn btn-outline-success dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            اتمام
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#" @click.prevent="updateStatus(0,task.id)">برای انجام</a></li>
            <li><a class="dropdown-item" href="#" @click.prevent="updateStatus(1,task.id)">درحال انجام</a></li>
            <li><a class="dropdown-item" href="#" @click.prevent="updateStatus(2,task.id)">اتمام</a></li>
          </ul>
        </div>
      </th>
      <th>
        {{ task.created_at }}
      </th>
      <th>
        {{ task.updated_at }}
      </th>
      <th>
        <button v-if="loadingDelete[index]"  class="btn btn-secondary">
          <div class="spinner-border spinner-border-sm mb-1" role="status"></div>
          درحال حذف...
        </button>
        <div v-else class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            عملیات
          </button>
          <ul class="dropdown-menu">
            <li><router-link class="dropdown-item" :to="{name:'task-details',params:{id:task.id}}">نمایش</router-link></li>
            <li><a class="dropdown-item" href="#" @click.prevent="deleteTask(task.id,index)">حذف</a></li>
          </ul>
        </div>
      </th>
    </tr>
    </tbody>
  </table>
  <div v-if="loading" class="d-flex justify-content-center">
    <div class="spinner-border mb-1" role="status"></div>
  </div>
</template>

<style scoped>

</style>