<script>


import {reactive, ref} from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export default {
  name: "CreateUser",
  setup(){
    const loading = ref(false);
    const form = reactive({
      name: "",
      email: "",
      cellphone: "",
      job: "",
      password: "",
      validateError: "",
    });

    function CreateUser(){
      loading.value = true;
      axios
          .post(`https://support.afrandweb.com/api/api/users/create-user`,{
            name:form.name,
            email:form.email,
            cellphone:form.cellphone,
            job:form.job,
            password:form.password,
          },{
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          })
          .then(() => {
            loading.value = false;

            Swal.fire(
                'عملیات موفق',
                'کاربر با موفقیت ایجاد شد',
                'success'
            )
            router.push({name: 'users'})
          }).catch(function (error){
        loading.value = false;
        Swal.fire(
            'توجه',
            'خطا',
            'error'
        )
        console.log(error);
      });
    }

    return {form,CreateUser,loading}
  }
}
</script>

<template>
  <div class="row">

    <div class="col-xl-12 col-md-12 mb-4 p-md-5 bg-white">
      <div class="mb-4">
        <h5 class="font-weight-bold">ایجاد کاربر</h5>
      </div>
      <hr>
      <form @submit.prevent="CreateUser">

        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="name">نام</label>
            <input class="form-control" id="name" name="name" type="text" v-model.lazy.trim="form.name">
          </div>

          <div class="form-group col-md-3">
            <label for="name">ایمیل</label>
            <input class="form-control" id="email" name="email" type="email" v-model.lazy.trim="form.email">
          </div>

          <div class="form-group col-md-3">
            <label for="name">موبایل</label>
            <input class="form-control" id="cellphone" name="cellphone" v-model.lazy.trim="form.cellphone" type="number">
          </div>
          <div class="form-group col-md-3">
            <label for="name">سمت</label>
            <input class="form-control" id="job" v-model.lazy.trim="form.job" name="job" type="text">
          </div>
          <div class="form-group col-md-3">
            <label for="name">رمز عبور</label>
            <input class="form-control" id="password" v-model.lazy.trim="form.password" name="password" type="password">
          </div>
        </div>
        <button v-if="loading"  class="btn btn-primary mt-5">
          <div class="spinner-border spinner-border-sm mb-1" role="status"></div>
          درحال ایجاد...
        </button>



        <button v-else class="btn btn-outline-primary mt-5" type="submit">ثبت</button>
        <router-link :to="{name:'users'}" class="btn btn-dark mt-5 mr-3">بازگشت</router-link>
      </form>
    </div>

  </div>
</template>

<style scoped>

</style>