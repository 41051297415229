import { createStore } from "vuex";

export default createStore({
    state: {
        userId: localStorage.getItem('token') || null,
        admin: localStorage.getItem('admin') || null,
    },
    mutations: {
        setToken(state, userId) {
            state.userId = userId;
            localStorage.setItem('token', userId);
        },
        setAdmin(state, admin) {
            state.admin = admin;
            localStorage.setItem('admin', admin);
        },
    },
    actions: {
        setToken({ commit }, userId) {
            commit('setToken', userId);
        },
        setAdmin({ commit }, admin) {
            commit('admin', admin);
        },
    },
});