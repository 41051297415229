<template>
  <router-view></router-view>
</template>

<script>

// import "@/assets/js/sb-admin-2.min";



export default {
  name: 'App',

}
</script>

<style>

@import "@/assets/css/sb-admin-2.min.css";
@import "@/assets/vendor/fontawesome-free/css/all.min.css";


</style>
