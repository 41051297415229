<script>
export default {
  name: "UserCard",
  props: {
    user: Object,
    deletePost:Function,
    loading:Function,
    admin:Function,
  }
}
</script>

<template>
  <div>
    <div class="d-flex mb-2 mt-2" v-if="admin">
      <router-link :to="{ name : 'create' }" class="btn btn-dark">ایجاد کاربر</router-link>
    </div>
    <table class="table table-bordered table-striped text-center">

      <thead>
      <tr>
        <th>#</th>
        <th>نام</th>
        <th>ایمیل</th>
        <th>موبایل</th>
        <th>سمت</th>
        <th>عملیات</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(users, index) in user" :key="users.id">
        <th>
          {{ (index + 1) }}
        </th>
        <th>
          {{users.name}}
        </th>
        <th>
          {{users.email}}
        </th>
        <th>
          {{users.cellphone}}
        </th>
        <th>
          {{users.job}}
        </th>
        <th>
          <button v-if="loading[index]"  class="btn btn-secondary">
            <div class="spinner-border spinner-border-sm mb-1" role="status"></div>
            درحال حذف...
          </button>
          <div class="dropdown" v-else>

            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" v-if="admin">
              عملیات
            </button>
            <router-link :to="{name:'tasks',params: {id:users.id,title:'test'},query: { title: users.name } }" class="btn btn-secondary" type="button" v-else>
              نمایش تسک
            </router-link>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" :to="{name:'tasks',params: {id:users.id,title:'test'},query: { title: users.name } }">تسک ها</router-link></li>
              <li><a class="dropdown-item" href="#" @click="() => deletePost(users.id,index)">حذف</a></li>
            </ul>


          </div>
        </th>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

</style>