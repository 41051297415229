<script>
import axios from "axios";
import {ref} from "vue";
import UserCard from "@/components/UserCard.vue";
import Swal from "sweetalert2";
import EncryptionUtility from "@/encrypt";


export default {

  name: "UsersPage",
  components: {UserCard},
  setup() {
    const isAdmin = ref(false);
    const data = ref([]);
    const loading = ref(true);
    const loadingDelete = ref([]);

    function AdminChecker(){
      const admin = localStorage.getItem("admin")
      const encryptionUtility = new EncryptionUtility("AdminKey");
      const data = encryptionUtility.decrypt(admin)
      isAdmin.value = data === "1";
    }
    AdminChecker();
    function DeleteUser(userId,index) {
      loadingDelete.value[index] = true;
      axios
          .delete(`https://support.afrandweb.com/api/api/users/${userId}`,{
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          })
          .then(() => {
            loadingDelete.value[index] = false;

            Swal.fire(
                'عملیات موفق',
                'کاربر با موفقیت حذف شد',
                'success'
            )
            getUserData()
          }).catch(function () {
        loadingDelete.value[index] = false;

        Swal.fire(
            'توجه',
            'برای حذف کاربر ابتدا تسک های آن را پاک کنید!',
            'error'
        )
      });
    }

    function getUserData() {
      axios
          .get(`https://support.afrandweb.com/api/api/users`,{
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          })
          .then((response) => {
            loading.value = false;
            data.value = response.data;
            loadingDelete.value = new Array(response.data.length).fill(false);

          }).catch(function (error) {
        loading.value = false;

        console.log(error);
      });
    }

    getUserData()

    return {data, DeleteUser, loading,loadingDelete,isAdmin}
  }
}
</script>

<template>
  <h2 v-if="isAdmin">لیست کاربران</h2>
  <h2 v-else>اطلاعات کاربری</h2>


  <UserCard :user="data" :deletePost="DeleteUser" :loading="loadingDelete" :admin="isAdmin"/>

  <div v-if="loading" class="d-flex justify-content-center">
    <div class="spinner-border mb-1" role="status"></div>
  </div>
</template>

<style scoped>

</style>