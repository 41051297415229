<script>


import {reactive, ref} from "vue";

import Editor from '@tinymce/tinymce-vue'
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";
export default {
  name: "CreateTask",

  components: {
    'editor': Editor
  },
  setup(){
    const loading = ref(false);
    const users = ref([]);
    const editorContent = ref('');

    const form = reactive({
      title: "",
      userId: "",
      priority: "",
      content: "",
      validateError: "",
    });

    function storeTask(){
      loading.value = true;
      axios.post('https://support.afrandweb.com/api/api/tasks/store',{
        userId : form.userId,
        title : form.title,
        desc : form.content,
        priority : form.priority,
      },{
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then(()=>{
        loading.value = false;

        Swal.fire(
            'عملیات موفق',
            'تسک باموفقیت ایجاد شد!',
            'success'
        )
        router.push({name: 'tasks',params:{'id':form.userId}});
      }).catch(function (){
        loading.value = false;

        Swal.fire(
            'عملیات ناموفق',
            'خطا!',
            'error'
        )
      });
    }

    function getUsers(){
      axios.get('https://support.afrandweb.com/api/api/users',{
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response)=>{
        users.value = response.data;
      }).catch(function (){
      });
    }


    getUsers();

    return {form,CreateUser: storeTask,loading,editorContent,getUsers,users}
  }
}
</script>

<template>
  <div class="row">

    <div class="col-xl-12 col-md-12 mb-4 p-md-5 bg-white">
      <div class="mb-4">
        <h5 class="font-weight-bold">ایجاد تسک</h5>
      </div>
      <hr>
      <form @submit.prevent="CreateUser">

        <div class="form-row">
          <div class="col-md-3 mb-3">
            <label for="validationTooltip04">کاربر</label>
            <select class="custom-select" id="validationTooltip04" required v-model.lazy.trim="form.userId">
              <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }}</option>
            </select>
            <div class="invalid-tooltip">
              Please select a valid state.
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="name">عنوان تسک</label>
            <input class="form-control" id="name" name="name" type="text" v-model.lazy.trim="form.title">
          </div>

          <div class="col-md-3 mb-3">
            <label for="validationTooltip05">اولویت</label>
            <select class="custom-select" id="validationTooltip05" required v-model.lazy.trim="form.priority">
              <option value="2">بالا</option>
              <option value="1">متوسط</option>
              <option value="0">پایین</option>
            </select>
            <div class="invalid-tooltip">
              لطفا فیلد را پر کنید!
            </div>
          </div>


        </div>
        <editor
            api-key="1a47p7ikui2s1sudkybdrnfhj3d9zg6mfn22tou7w31b2sxw"
            :init="{
         height: 500,
         menubar: true,
         plugins: [
           'advlist autolink lists link image charmap print preview anchor',
           'searchreplace visualblocks code fullscreen',
           'insertdatetime media table paste code help wordcount'
         ],
         toolbar:
           'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
       }"
            v-model="form.content"
        />
        <button v-if="loading"  class="btn btn-primary mt-5">
          <div class="spinner-border spinner-border-sm mb-1" role="status"></div>
          درحال ایجاد...
        </button>

        <button v-else class="btn btn-outline-primary mt-5" type="submit">ثبت</button>
        <button class="btn btn-dark mt-5 mr-3" @click="$router.go(-1)">بازگشت</button>

      </form>
    </div>

  </div>
</template>

<style scoped>

</style>