<script>
import EncryptionUtility from "@/encrypt";
import {ref} from "vue";

export default {
  name:"SideBar",
  setup(){
    const isAdmin=ref(false);
    function AdminChecker(){
      const admin = localStorage.getItem('admin');
      const encryptionUtility = new EncryptionUtility("AdminKey");
      const data = encryptionUtility.decrypt(admin)
      isAdmin.value = data === "1";
    }
    AdminChecker()

    return {isAdmin}
  }
}
</script>

<template>
  <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion pr-0" id="accordionSidebar">

    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="https://afrandweb.com/">
      <div class="sidebar-brand-icon rotate-n-15">
        <i class="fas fa-laugh-wink"></i>
      </div>
      <div class="sidebar-brand-text mx-3">AfrandWeb.com</div>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0">




    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item">
      <router-link class="nav-link collapsed" :to="{name:'users'}" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
                   aria-controls="collapseTwo">
        <i class="fas fa-fw fa-cog"></i>
        <span v-if="isAdmin"> کاربران </span>
        <span v-else> پروفایل </span>

      </router-link>
      <router-link class="nav-link collapsed" :to="{name:'create-task'}" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
                   aria-controls="collapseTwo">
        <i class="fas fa-fw fa-tasks"></i>
        <span> ایجاد تسک </span>

      </router-link>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header"> کامپونت سفارشی : </h6>
          <a class="collapse-item" href="#">Buttons</a>
          <a class="collapse-item" href="#">Cards</a>
        </div>
      </div>
    </li>

    <!-- Nav Item - Utilities Collapse Menu -->





    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block">

    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
      <button class="rounded-circle border-0" id="sidebarToggle"></button>
    </div>

  </ul>
</template>

<style scoped>

</style>