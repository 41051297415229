<script >
import axios from "axios";
import {ref} from "vue";
import {useRoute} from "vue-router";

export default {
  name:"TaskDetails",
  setup(){
    const router = useRoute();

    const data = ref({});
    function getData(taskId){
      axios.get(`https://support.afrandweb.com/api/api/tasks/details/${taskId}`,{
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      })
          .then((response)=>{
            data.value = response.data;
          }).catch(function (){

      });
    }
    getData(router.params.id)

    return {data};
  }
}
</script>

<template>
<div v-html="data.description">
</div>
</template>

<style scoped>

</style>